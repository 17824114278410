<template>
  <div>
    <div class="bill-title">仓库盘点</div>
    <el-form label-width="70px">
      <el-row>
        <el-col :span="6">
          <el-form-item label="编号">
            <el-tag type="info">{{ billInfo.billCode }}</el-tag>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="日期">
            <el-tag type="info">{{ billInfo.billDate | datetime }}</el-tag>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="仓库">
            <el-tag type="info">{{ billInfo.store }}</el-tag>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="备注">
        <el-tag v-if="billInfo.remark" type="info">{{
          billInfo.remark
        }}</el-tag>
      </el-form-item>
      <el-form-item label="附件">
        <BillAttach :attachId="billInfo.attachId"></BillAttach>
      </el-form-item>
      <div div style="margin: -8px 0px 8px 60px">
        <el-table
          :data="billInfo.detail"
          :row-class-name="tableRowClassName"
          stripe
          border
        >
          <el-table-column type="index"></el-table-column>
          <el-table-column label="编号" prop="goodsCode"></el-table-column>
          <el-table-column label="名称" prop="goodsName"></el-table-column>
          <el-table-column label="规格" prop="goodsSpec"></el-table-column>
          <el-table-column label="单位" prop="goodsUnit"></el-table-column>
          <el-table-column label="盈亏">
            <template slot-scope="scope">{{
              scope.row.breakevenQuantity
            }}</template>
          </el-table-column>
        </el-table>
      </div>

      <el-form-item>
        <el-button-group>
          <el-button type="danger" @click="deleteBill">删除</el-button>
          <el-button type="warning" @click="cancel">关闭</el-button>
        </el-button-group>
      </el-form-item>
      <el-form-item>
        <operator-box :item="billInfo"></operator-box>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import OperatorBox from "@/components/OperatorBox";
import BillAttach from "@/components/BillAttach";
export default {
  components: {
    OperatorBox,
    BillAttach,
  },
  data() {
    return {
      routeTab: "盘点单",
      billInfo: {},
    };
  },
  activated() {
    this.getData(this.$route.params.id);
  },
  methods: {
    tableRowClassName({ row }) {
      let diff = row.breakevenQuantity;
      if (diff < 0) {
        return "warning-row";
      } else if (diff > 0) {
        return "success-row";
      } else {
        return "";
      }
    },
    getData(id) {
      this.$get("Inventory/GetBillInfo", {
        id,
      }).then((r) => {
        this.billInfo = r;
        this.routeTab = this.billInfo.billCode;
      });
    },
    deleteBill() {
      this.$confirm("将删除此盘点单, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let loading = this.$loading({
            lock: true,
            text: "删除中...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          this.$post("Inventory/Delete?id=" + this.billInfo.id)
            .then((r) => {
              this.$message.success("操作成功！");
              this.$g.deleteAttach(this.billInfo.attachId);
              this.$tabs.close();
            })
            .finally(() => {
              loading.close();
            });
        })
        .catch(() => {});
    },
    cancel() {
      this.$tabs.close();
    },
  },
  beforeRouteUpdate(to, from, next) {
    this.getData(to.params.id);
    next();
  },
};
</script>

<style>
</style>