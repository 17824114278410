<template>
  <div>
    <el-button type="text" size="medium" @click="showDialog">
      <i class="el-icon-paperclip"></i>附件
    </el-button>
    <el-dialog
      title="附件"
      :visible.sync="visible"
      append-to-body
      v-dialogDrag
      width="40%"
      :close-on-click-modal="false"
    >
      <el-table :data="tableArray" stripe border v-loading="loading">
        <el-table-column label="名称" prop="name" show-overflow-tooltip></el-table-column>
        <el-table-column label="更新时间" width="150">
          <template slot-scope="scope">{{scope.row.lastModified|datetime('YYYY-MM-DD HH:mm:ss')}}</template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-tag type="success" @click="downloadAttach(scope.row)">
              <i class="el-icon-download"></i>
            </el-tag>

            <el-tag type="danger" style="margin-left:6px" @click="deleteAttach(scope.row)">
              <i class="iconfont iconclose" style="font-size: 10px;"></i>
            </el-tag>
          </template>
        </el-table-column>
      </el-table>
      <div class="foot">
        <el-upload action :auto-upload="false" :show-file-list="false" :on-change="uploadAttach">
          <el-button slot="trigger" icon="el-icon-upload2" type="primary">上传附件</el-button>
        </el-upload>
        <el-button
          style="margin-left:10px"
          v-show="showImgViewer"
          type="info"
          @click="showImg"
          icon="el-icon-search"
        >预览图片</el-button>
      </div>
      <viewer :images="images" @inited="inited">
        <img v-for="src in images" :src="src" :key="src" class="image" />
      </viewer>
    </el-dialog>
  </div>
</template>

<script>
import oss from "@/common/oss";
export default {
  props: ["attachId"],
  data() {
    return {
      visible: false,
      tableArray: [],
      images: [],
      loading: false,
    };
  },
  computed: {
    dir() {
      return this.$store.state.userInfo.companyId + "/" + this.attachId + "/";
    },
    showImgViewer() {
      return this.images.length > 0;
    },
  },
  methods: {
    showDialog() {
      this.setArray();
      this.visible = true;
    },
    setArray() {
      this.tableArray = [];
      this.images = [];
      this.loading = true;
      oss("zxhd-attach").then((client) => {
        client
          .list({
            prefix: this.dir,
            delimiter: "/",
          })
          .then((res) => {
            if (res.objects) {
              res.objects.forEach((item) => {
                this.setTableArray(item);
                this.setImgArray(item);
              });
            }
            this.loading = false;
          });
      });
    },
    setTableArray(item) {
      this.tableArray.push({
        key: item.name,
        name: item.name.replace(this.dir, ""),
        lastModified: item.lastModified,
      });
    },
    setImgArray(item) {
      let key = item.name;
      let strs = key.split(".");

      if (
        strs &&
        strs.length &&
        ["jpg", "png", "jpeg"].indexOf(strs[strs.length - 1].toLowerCase()) >= 0
      ) {
        oss("zxhd-attach").then((client) => {
          let url = client.signatureUrl(key);
          this.images.push(url);
        });
      }
    },
    inited(viewer) {
      this.$viewer = viewer;
    },
    showImg() {
      this.$viewer.show();
    },
    downloadAttach(row) {
      oss("zxhd-attach").then((client) => {
        const url = client.signatureUrl(row.key);
        window.open(url);
      });
    },
    uploadAttach(file) {
      let key = this.dir + file.name;
      oss("zxhd-attach").then((client) => {
        client.put(key, file.raw).then(() => {
          this.$message.success("上传成功");
          this.setArray();
        });
      });
    },
    deleteAttach(row) {
      oss("zxhd-attach").then((client) => {
        client.delete(row.key).then(() => {
          this.setArray();
        });
      });
    },
  },
};
</script>

<style scoped>
.foot {
  margin-top: 10px;
  display: flex;
}
.image {
  display: none;
}
</style>